<template>
  <div class="page flex-col">
    <div class="box">
      <div class="boxTop">
        <div class="title"></div>
        <!-- <span>我们将与合作伙伴协商后，提供优惠的投放方案</span> -->
      </div>
      <div class="listBox">
        <div class="liBox">
          <img src="../../assets/img/serveTime.png" alt="" />
          <div class="topText">服务时间</div>
          <div class="hiti">
            <span>周一至周六</span>
            <br />
            <span>9:00-20:00</span>
          </div>
        </div>
        <div class="liBox">
          <img src="../../assets/img/contactWay.png" alt="" />
          <div class="topText">联系方式</div>
          <div class="hiti">
            <span>邮箱：wenjie.huang@daping.cn</span>
            <br />
            <span>电话：400-998-9272</span>
          </div>
        </div>
      </div>
    </div>
    <div class="organization flex-col align-center">
      <div class="view-title flex-row align-center">
        <div class="border-left"></div>
        <span class="txt">合作公益组织</span>
      </div>
      <div class="content flex-col">
        <div class="content-top flex-row align-center justify-between">
          <img src="../../assets/img/zbzLogo.jpg" alt="" />
          <span
            >肇庆市自闭症互助协会是2014年5月，由部分自闭症人士的家长联合市内各自闭症康复机构、康复专业人士以及社会爱心人士共同发起组成、经民政部门注册的社会公益团体。</span
          >
        </div>
        <div class="content-bottom flex-col">
          <span
            >协会旨在通过自闭症知识宣传、政策倡导、学习培训、融合活动、提供合适服务等形式，帮助肇庆市自闭症人士持续提升生活、学习、就业技能及品质，
            推动本市自闭症人士教育、就业以及养护等各项福利措施的发展，缓解自闭症人士家长的心理压力和经济压力，提高全社会对自闭症人群的关注和包容，
            从而提高自闭症人士及其家庭的生活质量和幸福指数，促进和谐社会的构建。</span
          >
          <span>协会愿景：引领自闭症人士前进的明灯</span>
          <span>协会使命：汇人间温暖&nbsp;&nbsp;创自闭坦途</span>
        </div>
      </div>
      <div class="contact flex-row justify-between">
        <div class="contact-left flex-col">
          <span class="txt1">理解·包容·接纳·援助</span>
          <span class="txt2"
            >关爱自闭症人士，加入肇庆自闭症互助协会这个大家庭！</span
          >
          <div class="contact-num flex-row">
            <span class="span1">电话：13415768236</span>
            <span class="span2">邮箱：zqautism@163.com</span>
          </div>
          <span class="txt3"
            >办公地址：肇庆市端州区星湖大道仙女湖东侧维龙园区2号楼二楼205室</span
          >
        </div>
        <div class="contact-right">
          <img src="../../assets/img/zbzQrCode.jpg" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.page {
  width: 100%;
  min-height: calc(100vh - 233rem);
  padding-top: 48rem;
  padding-bottom: 100rem;

  .box {
    width: 1280rem;
    height: 644rem;
    margin: 0 auto;

    .boxTop {
      width: 1280rem;
      height: 231rem;
      background: url("../../assets/img/topBack.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      padding-top: 94rem;
      text-align: center;
      border-radius: 8rem 8rem 0 0;
      .title {
        width: 628rem;
        height: 124rem;
        background: url("../../assets/img/topText.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        margin: 0 auto;
        margin-bottom: 8rem;
      }

      span {
        // width: 420rem;
        // height: 28rem;
        font-size: 20rem;
        font-weight: 400;
        color: #7c7e7f;
        line-height: 28rem;
        margin-top: 14rem;
      }
    }

    .listBox {
      width: 1280rem;
      height: 413rem;
      display: flex;
      background: #ffffff;
      padding-top: 83rem;
      padding-left: 194rem;
      border-radius: 0 0 8rem 8rem;

      .liBox {
        margin-right: 40rem;
        text-align: center;
        width: 426rem;
        height: 238rem;
        background: #ffffff;
        // border: 1rem solid #91E7F6;
        background: url("../../assets/img/msgBack.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        border-radius: 12rem;
        img {
          width: 72rem;
          height: 72rem;
          margin-top: 32rem;
        }

        .topText {
          margin: 0 auto;
          margin-top: 18rem;
          // width: 88rem;
          // height: 30rem;
          font-size: 22rem;
          font-weight: 400;
          color: #2bb3e3;
          line-height: 30rem;
        }

        .hiti {
          margin-top: 8rem;
          font-size: 16rem;
          font-weight: 400;
          color: #6f6f6f;
          line-height: 26rem;
        }
      }
    }
  }
  .organization {
    width: 1280rem;
    margin: 0 auto;
    background-color: #FFFFFF;
    .view-title {
      width: 892rem;
      .border-left {
        width: 3rem;
        height: 18rem;
        background: linear-gradient(225deg, #4facfe 0%, #0ae1ec 100%);
        margin-right: 17rem;
      }
      .txt {
        font-size: 20rem;
        color: #2d2d2d;
        line-height: 28rem;
      }
    }
    .content {
      width: 892rem;
      background: #fbfbfb;
      background: url("../../assets/img/msgBack.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      border-radius: 20rem;
      padding: 34rem;
      margin-top: 34rem;
      .content-top {
        img {
          width: 373rem;
          height: 229rem;
          border-radius: 20rem;
        }
        span {
          display: inline-block;
          width: 426rem;
          font-size: 18rem;
          color: #6f6f6f;
          line-height: 36rem;
        }
      }
      .content-bottom {
        margin-top: 14rem;
        span {
          font-size: 18rem;
          color: #6f6f6f;
          line-height: 36rem;
          margin-top: 10rem;
        }
      }
    }
    .contact {
      width: 892rem;
      background: #fbfbfb;
      background: url("../../assets/img/msgBack.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      border-radius: 20rem;
      padding: 32rem 34rem;
      margin: 40rem 0 80rem;
      .contact-left {
        .txt1 {
          font-size: 20rem;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #2bb3e3;
          line-height: 28rem;
        }
        .txt2 {
          font-size: 18rem;
          color: #a7a7a7;
          line-height: 25rem;
          margin-top: 10rem;
        }
        .contact-num {
          margin-top: 40rem;
          .span1,
          .span2 {
            font-size: 18rem;
            color: #6f6f6f;
            line-height: 25rem;
          }
          .span2 {
            margin-left: 40rem;
          }
        }
        .txt3 {
          font-size: 18rem;
          color: #6f6f6f;
          line-height: 25rem;
          margin-top: 12rem;
        }
      }
      .contact-right {
        img {
          width: 165rem;
          height: 165rem;
        }
      }
    }
  }
}
</style>
